#tabContainer {
  justify-content: space-between;
  display: flex;
  padding: 0 3px;
  cursor: pointer; }

.tab {
  padding: 3px 5px;
  background: #CDCDCD;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.1), 0 4px 10px 0 rgba(0,0,0,.1);
  flex: 1;
  text-align: center; }

.active_tab {
  background: white; }

.disabled_tab {
  background: #F0F0F0;
  cursor: not-allowed; }
