@import "variables";

.topbar-left {
  background-color: white; }

.topbar {
  background-color: $Blue;
  position: fixed; }

.cp-topbar {
  background-color: white !important;
  position: fixed;
  left: 0px;
  right: 0;
  top: 0px;
  height: 70px;
  z-index: 11;
  box-shadow: 2px 2px 2px 0px rgba(5, 5, 5, 0.06); }

.header-logo {
  max-width: 100%;
  height: 60px; }

.navbar-form {
  background-color: $paleBlue; }

.navbar-form .form-control {
  color: #030303; }

.search-button i {
  color: #030303; }

#userGreeting {
  margin-right: 50px;
  margin-top: 23px;
  color: white; }

.topbar {
  left: 0px;
  right: 0px;
  top: 0px;
  height: 70px;
  z-index: 11;
  box-shadow: 2px 2px 2px 0px rgba(5,5,5,0.06);
  margin-bottom: 50px; }

.topbar-left {
  float: left;
  height: 70px;
  position: relative;
  z-index: 1;
  width: 285px; }

.topbar-right {
  float: right;
  height: 70px;
  position: relative;
  z-index: 1;
  width: 150px; }

.navbar {
  margin: 0 0 0 -15px;
  border-radius: 0;
  border: none; }

.button-menu-mobile {
  float: left;
  top: 0px;
  z-index: 2;
  padding: 0px 18px;
  height: 70px;
  font-size: 21px;
  background: transparent;
  color: rgba(255, 255, 255, 0.5);
  border: none; }

.button-menu-mobile:hover {
  color: #fff; }

.topbar .title {
  float: left;
  font-family: 'Montserrat', sans-serif;
  //font-family: "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, sans-serif
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 1px;
  margin-top: 15px;
  padding-left: 0px;
  text-transform: uppercase; }

//#wrapper.enlarged .topbar .topbar-left .logo
//  opacity: 0
//  display: none
