.side-menu {
  width: 260px;
  top: 0;
  bottom: 0;
  z-index: 2; }

.side-menu.left {
  position: absolute;
  top: 96px;
  left: 26px; }

.fixed-left-void .side-menu.left {
  display: none; }

body.fixed-left .side-menu.left {
  margin-top: 0px;
  bottom: 30px; }

.side-menu.left .scroll-y {
  direction: rtl;
  overflow-x: visible; }


#sidebar-menu {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  font-weight: normal;
  text-decoration: none;
  line-height: 1;
  font-size: 13px;
  position: relative;
  ul, li {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none;
    font-weight: normal;
    text-decoration: none;
    line-height: 1;
    font-size: 13px;
    position: relative; }
  a {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none;
    font-weight: normal;
    text-decoration: none;
    line-height: 1;
    font-size: 13px;
    position: relative;
    line-height: 1.3; }
  width: 100%;
  font-family: "open-sans", sans-serif;
  background: #263238;
  > ul > li {
    display: block;
    > a {
      padding: 16px 20px 16px 23px;
      display: block;
      color: #fafafa;
      font-size: 14px;
      /* border-bottom: 1px rgba(255,255,255,0.2) solid; */
      > span {
        vertical-align: middle; }
      &:hover {
        border-left: 6px #139fe1 solid;
        border-right: 6px $Blue solid;
        padding-left: 17px;
        text-decoration: none; }
      > i {
        width: 20px;
        font-size: 14px;
        &.i-right {
          margin: 3px 0 0 0;
          float: right; } }
      &.subdrop {
        background: rgba(0, 0, 0, 0.64);
        border-left: 6px $Blue solid;

        padding-left: 17px; }
      &.active {
        color: #fff;
        background: #68C39F !important;
        border-left: 5px solid rgba(0, 0, 0, 0.3);
        padding-left: 5px;
        &.subdrop {
          color: #fff;
          background: #68C39F !important;
          border-left: 5px solid rgba(0, 0, 0, 0.3);
          padding-left: 5px; } }
      &.open:after, &.active:after {
        border-color: rgba(0, 0, 0, 0) #E4EAE6 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
        border-style: solid;
        border-width: 15px 11px 15px 0;
        content: " ";
        height: 0;
        position: absolute;
        right: 0;
        top: 6px;
        width: 0;
        z-index: 2; }
      span.label {
        float: right;
        margin-right: 10px;
        margin-top: 1px;
        &.span-left {
          float: none;
          margin-left: 5px; } } } }
  span.cnt {
    position: absolute;
    top: 8px;
    right: 15px;
    padding: 0;
    margin: 0;
    background: none; }
  ul ul {
    display: none;
    background: rgba(0, 0, 0, 0.64);
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    ul {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      background: rgba(0, 0, 0, 0.2); }
    li {
      border-top: 0; }
    a {
      padding: 10px 50px;
      display: block;
      color: #ffffff;
      font-size: 13px; }
    ul {
      a {
        padding-left: 35px; }
      ul a {
        padding-left: 45px; } }
    a:hover {
      color: #fff;
      background: #1a2926; }
    li a.active {
      color: #fff;
      background: #0f1010; }
    a i {
      margin-right: 5px; } } }

#wrapper.enlarged {
  #sidebar-menu ul ul {
    border: none; }
  .left.side-menu .widget {
    display: none; }
  .profile-text {}
  .profile-info {
    .col-xs-4 {
      width: 100%;
      padding: 5px; }
    .col-xs-8 {
      width: 100%;
      padding: 0 7px; } }
  .left.side-menu {
    z-index: 5; }
  .content-page {
    margin-left: 0px; }
  .left.side-menu .navbar-form {
    input {
      &::-webkit-input-placeholder, &:-moz-placeholder, &::-moz-placeholder, &:-ms-input-placeholder {
        color: transparent !important; } }
    &:hover {
      width: 280px;
      position: relative;
      z-index: 5; } }
  .topbar .topbar-left {
    width: 0px !important;
    display: none; } }
