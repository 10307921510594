@import "variables";

#quickFilterButtonDiv {
  padding-top: 5px; }

#candidateIndex {
  .dataTables_filter {
    label {
      display: none; } }
  .candidateSearch {
    input {
      padding-left: 17px; }
    .fa-search {
      position: absolute;
      top: 5px;
      left: 15px; }
    .fa-times {
      &:hover {
        cursor: pointer; }
      display: none;
      position: absolute;
      top: 5px;
      right: 15px; } } }

#candidateSearch {
  width: 100%; }

#addNewCandidateBtn {
  margin-bottom: 15px; }

#createCandidateRecord {
  margin-right: 15px; }

#candidateProgressBar {
  .progress {
    margin-bottom: 0; } }

#noFirstName, #noLastName {
  padding-left: 15px; }

#candidateAgeContainer {
  padding: 0; }

#candidateAgeEquals {
  position: absolute;
  z-index: 100;
  float: left;
  padding-top: 2px; }

#calcCandidateAge[disabled] {
  background-color: #fff !important;
  cursor: default; }

#candidateDobContainer {
  padding-right: 0; }

.candidate-form-left {
  padding-left: 0; }

.candidate-form-right {
  padding-right: 0; }

.input-group-addon {
  padding: 0; }

.input-group-addon-btn {
  margin-top: 1px;
  margin-left: 1px; }

.add-new-candidate-form {
  background-color: white; }

.candidate-qual-input {
  padding-left: 5px;
  padding-right: 5px; }

.candidate-qual-input-button {
  @extend .candidate-qual-input;
  margin-top: 26px; }

.bottom-margin-0 {
  margin-bottom: 0; }

.candidate-profile-img {
  max-height: 210px;
  max-width: 100%; }

.subcategory__hidden {
  display: none; }

#candidateEnrolNav {
  width: -webkit-calc(100% - 300px);
  width:    -moz-calc(100% - 300px);
  width:         calc(100% - 300px);
  position: fixed;
  z-index: 9;
  left: 300px;
  top: 69px; }

#candidateEnrolNav a {
  text-align: center;
  font-size: 16px;
  color: #f3f3f3;
  display: block; }
//margin-top: 10px

.enrol-nav-btn {
  padding-top: 10px;
  height: 40px;
  background-color: $Blue; }

.enrol-nav-btn:hover {
  background-color: #30c6ff;
  color: black; }

.candidate-enrol-section {
  margin-top: 50px; }

.truncate {
  width: 254px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.staff-enrol-panel {
  padding: 15px 25px;
  border-radius: 5px;
  border: 1px $Blue solid;
  color: #006c96;
  font-size: 16px;
  box-shadow: inset 2px 2px 5px rgba(0,0,0,0.1); }

.clickable {
  cursor: pointer; }

.font-16 {
  font-size: 16px; }

.fs-score-container {
  position: relative; }

.percent-symbol {
  position: absolute;
  right: 14px;
  top: 9%; }

.right-align-input {
  text-align: right;
  padding-right: 14px; }

.commitment-activity__table {
  td {
    vertical-align: middle !important; } }

.vertical-padding-3 {
  margin: 3px 0; }

.work-experience:not(:last-child) {
  margin-bottom: 10px; }
