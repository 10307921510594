@import "variables";

.activity-log-row {
  margin: 5px 0; }

.activity-panel {
  display: none;
  position: absolute;
  z-index: 2;
  bottom: 0;
  background-color: white;
  //display: block
  width: 95%;
  padding-bottom: 20px; }

#actionLogType {
  z-index: 2; }

.dropdown-menu {
  -webkit-box-shadow: 0 0 0 0 rgba(0,0,0,0);
  box-shadow: 0 0 0 0 rgba(0,0,0,0);
  -webkit-border-radius: 2px;
  border-radius: 2px;
  font-size: 12px;
  padding: 5px;
  background: #fff;
  border: 1px solid rgba(0,0,0,0.1);
  border-bottom: 1px solid #d2d2d2;
  box-shadow: inset 0px 1px 2px #fff;
  top: 100%;
  z-index: 10; }

.dropdown-menu > li > a {
  padding: 5px 7px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  font-size: 13px;
  color: #555; }

.dropdown-menu > li > a:hover {
  background: #aaa;
  color: #fff; }
