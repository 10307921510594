@import "variables";

//Button Components

.btn {
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid transparent; }

.btn-default {
  background-color: $blue80;
  color: #fff;
  border-color: #e5e5e5;
  border-radius: 5px;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'open-sans', sans-serif; }

.btn-danger {
  @extend .btn-default;
  background-color: #dd4b39;
  border-color: #e5e5e5; }

.btn-group-justified > .btn-group:first-child > a > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group-justified > .btn-group:last-child > a > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }


.btn-default--radio {
  background-color: #fff;
  color: $Blue;
  border-color: $Blue;
  border-radius: 5px; }

.btn-default--submit {
  border-radius: 5px; }

.btn-default:hover,
.btn-default:active,
.btn-default.hover,
.btn-default.active {
  background-color: $blue20;
  color: #444;
  border-color: #e5e5e5; }

.btn-default--radio:hover,
.btn-default--radio:active,
.btn-default--radio.hover,
.btn-default--radio.active {
  border: 1px solid #009ade;
  background-color: #009ade !important;
  color: #FFF !important;
  box-shadow: none !important; }


.btn-default.active:hover {
  background-color: $blue40;
  border-color: #e5e5e5; }

.btn:focus {
  outline: none; }

.btn.btn-file {
  position: relative;
  overflow: hidden; }

.btn.btn-file>input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  opacity: 0;
  filter: alpha(opacity=0);
  outline: none;
  background: white;
  cursor: inherit;
  display: block; }

.btn-primary {
  background-color: #0083c1;
  border-color: #0083c1; }

.btn-primary:hover,
.btn-primary:active,
.btn-primary.hover {
  background-color: #333; }

.btn-success {
  background-color: #00a65a;
  border-color: #008d4c; }

.btn-success:hover,
.btn-success:active,
.btn-success.hover {
  background-color: #008d4c; }

.btn-info {
  background-color: #00c0ef;
  border-color: #00acd6; }

.btn-info:hover,
.btn-info:active,
.btn-info.hover {
  background-color: #00acd6; }


.btn-danger:hover,
.btn-danger:active,
.btn-danger.hover {
  background-color: #d73925; }

.btn-warning {
  background-color: #f39c12;
  border-color: #e08e0b; }

.btn-warning:hover,
.btn-warning:active,
.btn-warning.hover {
  background-color: #e08e0b; }

.enrolment-buttons {
  margin-bottom: 3px !important;
  margin-top: 3px !important;
  .btn {
    border: 0px; }
  .btn {
    border-right: 1px solid $LightGrey; }
  .btn:last-child {
    border-right: none; } }

