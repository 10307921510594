$Blue: #00a4e2;
$Grey: #818080;
$LightGrey : #c8c8c6;
$paleBlue : #d3e1f4;
$mutedBlue : #a1b9df;
$paleGreen : #c9e5db;
$brightGreen : #b9d478;
$palePurple : #eae2f2;
$brightPurple : #b27eb6;
$cpBlue: #009ade;
$cpGrey: #f2f2f2;
$cpDarkGrey: #D1D1D1;

$border : #e5e5e5;

$background : #eeeeed;

$blue80: rgba( $Blue, .8);
$blue60: rgba( $Blue, .6);
$blue40: rgba( $Blue, .4);
$blue20: rgba( $Blue, .2);

$grey80: rgba( $Grey, .8);
$grey60: rgba( $Grey, .6);
$grey40: rgba( $Grey, .4);
$grey20: rgba( $Grey, .2);
