@media(max-width: 768px) {
  .hide-xs {
    display: none; }

  #leftSideMenu {
    width: 285px;
    background-color: #fff;
    height: 100%;
    padding-left: 12px; }

  .side-menu.left {
    left: 0;
    //padding-left: 25px
    top: 70px; } }

@media(max-width: 992px) {
  .hide-sm {
    display: none; } }

@media(max-width: 1200px) {
  .hide-md {
    display: none; } }

