//Generic Form Components
.form-spacing {
  padding-top: 3px;
  padding-bottom: 3px; }

.form-row {
  padding-bottom: 2px;
  padding-top: 2px;
  margin-right: 0; }

.form-row>.col-md-6 {
  padding-right: 0;
 }  //margin-right: 15px

//.form-row:hover
//  background-color: #f8f8f7

//These are the styles for the form elements which do not pass validation
.form-control--incomplete {
  border: 1px solid #F44336 !important;
  background-color: #FFCDD2 !important; }

.form-control-label--incomplete {
  color: #E57373; }

.form-control:not(select) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.form-control {
  border-radius: 0;
  box-shadow: none;
  border-color: #e5e5e5;
  height: 28px;
  font-size: 13px; }

.form-control:focus {
  border-color: #3c8dbc;
  box-shadow: none; }

.input-group .input-group-addon {
  border-radius: 0;
  border-color: #d2d6de;
  background-color: #fff; }

.btn-default--submit {
  border-radius: 5px; }

//This is all test stuff for new fancy forms

input[type=text],
input[type=tel],
input[type=email],
input[type=date],
input[type=number],
input[type=password] {
  border-right: 0;
  border-left: 0;
  border-top: 0;
  border-bottom: 1px #808080 solid;
  //border-right: 1px #808080 solid
  float: right;
  display: inline-block;
  background-color: #fffffc;
  border-radius: 0px; }

input[type="file"] {
  display: block;
  padding: 0; }

.cand-form-first {
  margin-left: 18%; }


//.add-new-company-fieldset select
//  margin: 1px 20px
//  display: inline-block
//  float: right

//.add-new-company-fieldset label
//  margin: 5px 2%
//
//.add-new-company-fieldset input
//  margin: 1px 5%

.form-control-50-width {
  width: 50%; }

.form-control-25-width {
  width: 25%; }

.comp-form-addr, .margin-left-50 {
  margin-left: 50% !important; }

.form-control[disabled] {
  background-color: #eeeeed !important; }

.radio {
  margin-left: 10px;
  margin-top: 0;
  margin-bottom: 0; }

textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%; }

fieldset {
  margin-bottom: 20px; }

label {
  font-family: 'open-sans', sans-serif;
  font-size: 13px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 0; }

legend {
  margin-bottom: 10px;
  padding-bottom: 5px; }

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  -webkit-transition: background 0.2s linear;
  -moz-transition: background 0.2s linear;
  -ms-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
  transition: background 0.2s linear; }

textarea:focus,
select:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.m-uneditable-input:focus {
  background: $blue20;
  border-color: #eee;
  -webkit-transition: background 0.2s linear;
  -moz-transition: background 0.2s linear;
  -ms-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
  transition: background 0.2s linear; }

//Form Components for the Filters


.form-control--filter {
  border: 1px solid #aaa;
  border-radius: 4px;
  min-height: 36px; }

.btn-default-form--radio {
  height: 36px; }

.form-control--incomplete {
  border: 1px solid #F44336 !important;
  background-color: #FFCDD2 !important; }

.form-control-label--incomplete {
  color: #E57373; }

.togglable-label {
  margin-top: 5px; }

.lower-text-6 {
  padding-top: 6px; }
