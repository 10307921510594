@import "variables";

.cp-body {
  html {
    overflow-y: scroll; }

  .ui-tabs {
    height: 200px;
    border: none;
    border-radius: 10px; }

  .ui-corner-top {
    width: 33%;
    margin-right: 0.33% !important; }

  .ui-corner-top a {
    width: 100%; }

  .btn-default--cand {
    //border-radius: 10px
    //border-color: rgba(0, 164, 226, .8)
    height: 50px;
    font-size: 20px;
 }    //box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1)

  .btn-group--cand {
    height: 50px; }

  .btn-group--cand > .btn-default--cand:first-child {
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px; }

  .btn-group--cand > .btn-default--cand:last-child {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px; }

  #candPortalSocial {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    height: 50px;
    padding-top: 10px;
    margin-left: -10px; }

  .cand-dash-icon {
    display: block;
    margin: auto;
    text-align: center; }

  .cand-dash-icon-cont {
    width: 19%;
    display: inline-block; }

  .btn-default--radio {
    backgroud: #FFF;
    color: #303030;
    border: 1px solid #D1D1D1;
    border-radius: 0px !important;
    height: 50px; }

  .candidate-portal .select2-container--default .select2-selection--single,
  .candidate-portal .select2-container--default .select2-selection--multiple,
  .candidate-portal select {
    height: 52px !important;
    padding-top: 11px !important;
    width: 100% !important; }

  .candidate-portal .select2-selection__arrow {
    top: 11px !important; }

  .select2-container--default,
  .select-2--single-fix {
    width: 100% !important; }

  .candidate-portal label {
    font-size: 15px;
    color: #949494;
    font-weight: normal; }

  .candidate-portal .side label {
    position: absolute;
    top: 28px; }

  .candidate-portal h3 {
    font-size: 30px; }

  .candidate-portal p {
    font-size: 16px;
    margin-bottom: 0px; }

  .candidate-portal legend {
    margin: 0px;
    text-decoration: none;
    border: 0;
    padding: 30px 0; }

  @media(max-width: 1000px) {
    .candidate-portal {
      margin-top: 150px !important; } }

  @media(min-width: 1000px) and (max-width: 1200px) {
    .candidate-portal {
      margin-top: 170px !important; } }

  @media(min-width: 1201px) {
    .candidate-portal {
      margin-top: 0px !important; } }

  .candidate-portal textarea,
  .candidate-portal input[type=date],
  .candidate-portal input[type=email],
  .candidate-portal input[type=number],
  .candidate-portal input[type=password],
  .candidate-portal input[type=tel],
  .candidate-portal input[type=text] {
    background-color: white;
    border: 1px solid #D1D1D1;
    padding: 15px;
    border-radius: 5px;
    padding-top: 25px;
    padding-bottom: 25px;
    height: auto; }

  label.check {
    color: $cpBlue !important;
    font-weight: bold; }

  .select2-selection.select2-selection--single,
  .select2-selection.select2-selection--multiple {
    padding: 15px;
    border-radius: 5px;
    padding-top: 25px;
    padding-bottom: 25px;
    height: auto; }

  .select2-selection--single,
  .select2-selection--multiple,
  .select2-container--default,
  .select2-dropdown,
  .select2-dropdown--above {
    border-color: #D1D1D1 !important; }

  .select2-selection__arrow {
    color: #D1D1D1 !important; }

  .glyphicon.form-control-feedback {
    padding-top: 23px; }

  //.candidate-portal input[type=file]
  //    height: 35px

  .candidate-portal input, .candidate-portal input::-webkit-input-placeholder {
    font-size: 16px; }

  .candidate-portal .form-row--cand {
    margin-bottom: 20px;
    padding-bottom: 2px;
    padding-top: 2px;
    margin-right: 0; }

  //.enrol-panel
  //  background-color: $grey80
  //  padding: 5px
  //  border-radius: 5px
  //  color: white
  //  box-shadow: 2px 2px 5px rgba(0,0,0,0.1)

  .enrol-panel {
    padding: 15px 25px;
    border-radius: 5px;
    border: 1px #D1D1D1 solid;
    color: #000 !important;
    font-size: 16px;
    box-shadow: inset 2px 2px 5px rgba(0,0,0,0.1);
    background-color: #EFEFEF; }

  .enrol-panel p {
    padding-top: 8px;
    font-size: small; }

  .enrol-nav {
    position: fixed;
    width: 100%;
    //height: 40px
    background-color: $cpBlue;
    top: 69px;
    z-index: 500;
    margin-left: -11px; }

  .enrol-nav h4 {
    color: white !important;
    font-size: 14px !important;
    margin: 15px !important; }

  .enrol-nav a {
    text-align: center;
    font-size: 16px;
    color: #f3f3f3;
    display: block;
 }    //margin-top: 10px

  .enrol-nav-btn {
    padding-top: 10px;
    height: 40px;
    &.active {
      background-color: #30c6ff;
      color: black; } }

  .enrol-nav-btn:hover {
    background-color: #30c6ff;
    color: black; }

  .bootstrap-datetimepicker-widget {
    z-index: 999; }

  .stages-border {
    height: 2px;
    background: $cpDarkGrey;
    width: 530px;
    position: relative;
    top: 12px;
    left: 150px;
    z-index: -1; }

  .stages-wpr {
    width: 100%;
    margin: auto;
    position: fixed;
    padding: 20px;
    top: 120px; }

  .stages {
    width: 845px;
    margin: auto; }

  .stages a, .stages span {
    display: inline-block;
    color: $cpDarkGrey;
    margin-bottom: 5px;
    margin-left: 110px; }

  .stages a.active, .stages span.active {
    color: $cpBlue; }

  .stages .stage {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    padding: 0px;
    background: $cpGrey;
    border: 3px solid $cpDarkGrey;
    color: $cpDarkGrey;
    text-align: center;
    font-size: 12px;
    margin: 0 auto; }

  .stages .stage.active {
    border: 3px solid $cpBlue;
    color: $cpBlue; }

  .nav {
    position: fixed;
    background: $cpGrey;
    width: 100%;
    left: 0;
    margin: 0px;
    top: 210px;
    padding-top: 10px;
    margin-bottom: 10px;
    z-index: 999; }

  .nav.nav-tabs li {
    margin-left: 25px; }

  .nav.nav-tabs li > a, .nav.nav-tabs li > span {
    background: $cpDarkGrey;
    color: #fff; }

  .nav.nav-tabs li.active > a, .nav.nav-tabs li.active > span {
    background: $cpGrey;
    color: #666; }

  .nav.nav-tabs li:hover > a, .nav.nav-tabs li:hover > span {
    background: $cpGrey;
    color: #666;
    border: 1px solid #ddd; }

  .stages-wpr {
    z-index: 999;
    background: $cpGrey;
    width: 100%;
    left: 0px;
    margin: 0 auto; }

  .inner-addon {
    position: relative; }

  /* style icon */
  .inner-addon .glyphicon {
    position: absolute;
    padding: 10px;
    padding-top: 25px;
    pointer-events: none;
    color: $cpGrey; }

  /* align icon */
  .left-addon .glyphicon {
    left:  4px; }
  .right-addon .glyphicon {
    right: 25px; }

  /* add padding  */
  .left-addon input {
    padding-left:  60px; }
  .right-addon input {
    padding-right: 80px; }

  .tab-pane {
    margin-top: 100px; }

  .btn-cand {
    padding-top: 12px;
    padding-bottom: 12px;
    height: 50px;
    font-size: 16px;
    text-align: center;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

  .btn-cand:hover,
  .btn-cand:active {
    border: 1px solid $cpBlue;
    background-color: $cpBlue !important;
    color: #FFF !important;
    box-shadow: none !important; }

  .alert {
    margin-top: 100px;
    margin-bottom: -80px; }
  .alert-training-fix {
    margin-top: 0px !important;
    margin-bottom: 20px !important; } }
