@import "variables";
@import "components";
@import "buttonComponents";

//TODO add this class to every 50% width column
.col-md-6-left--custom {
  padding-right: 13px!important; }
.col-md-6-right--custom {
  padding-left: 13px!important; }

body {
  background-color: #eeeeed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.7em;
  font-size: 13px;
  font-family: "open-sans", sans-serif;
  //font-family: "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, sans-serif
  font-weight: normal;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  text-rendering: optimizelegibility;
  -webkit-text-size-adjust: 100%; }

#wrapper {
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: relative; }

* {
  outline: none !important; }

h1,h2,h3,h4,h5,h6 {
  margin: 10px 0;
  font-family: 'Montserrat', sans-serif;
 }  //font-family: "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, sans-serif

h1 {
  line-height: 43px; }

h2 {
  line-height: 35px; }

h3 {
  line-height: 30px; }

h4 {
  line-height: 22px; }

.content-wrapper {
  position: relative;
  padding: 26px 26px 5px 26px;
  //padding-bottom: 5px
 }  //padding-top: 30px

.no-sidebar {
  margin-left: 0px !important; }

.content-page {
  margin-left: 285px;
  overflow: hidden;
  position: relative; }

.content-page {
  .content {
    //padding: 0 25px 0 25px
    position: relative;
    //background: #fff
    //margin: 0 25px 0 25px
    //margin-top: 26px
 } }    //box-shadow: 0px 3px 8px -4px rgba(0,0,0,0.15)


.content-page {
  .content h4 {
    color: #111;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-top: 0;
    margin-top: 0; } }

.content-page {
  .content {
    .widget-content h2 {
      color: #5c6672;
      font-weight: bold;
      font-size: 16px;
      letter-spacing: normal;
      padding: 0;
      margin: 0; } } }

.full-width {
  width: 100%; }

.ile-alert {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 9999;
  background: $LightGrey; }

.alert-btn:hover {
  background-color: #d5d5d3; }

.alert-btn {
  padding: 14px 0; }

.ile-alert-body {
  padding: 15px; }

.ile-alert a {
  color: #000; }



//Remove bits styling
.hide {
  display: none !important; }

.no-border {
  border: 0 !important; }

.no-padding {
  padding: 0 !important; }

.no-margin {
  margin: 0 !important; }

.no-shadow {
  box-shadow: none!important; }

.right-align {
  float: right; }

.label a {
  color: #fff;
  text-decoration: underline; }

.label-sm {
  font-size: 11px; }


// Top Bar Styles
@import "topBar";

//Left Nav Bar Styles
@import "sidebar";

//Widget Styles
@import "widget";

//Styles for the customer section
@import "customer";

//Sidebar Styles Taken from the Original Theme CSS File
@import "sidebarOrig";

//Styles for the Login Screen
@import "login";

//Styles for the Candidates
@import "candidate";

//Styles for the Company
@import "company";

//Styles for the Settings Screen
@import "settings";

//Styles for the Modals
@import "modal";

//styles for the dashboard
@import "dashboard";

//styles for the staff profiles
@import "staff";

//Styles to hide stuff based on page size
@import "responsiveStyles";

//Styles for the Activity Log
@import "activityLog";

//Styles for the Form Components
@import "formComponents";

//Styles for the Candidate Portal
@import "candidatePortal";

//Styles for the ESignUp Screen
@import "esignup";

//Styles for the commitment Statement
@import "commitmentStatement";

//Styles for the Candidate Validation
@import "candidateValidation";

// Styles for Filters
@import "filters";

// Styles for tabs
@import "tabs";
