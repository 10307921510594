@import "variables";

.settings-button {
  height: 10vh; }

.settings-button-button {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-color: #f3f3f3;
  border-style: solid;
  margin-bottom: 1px; }
