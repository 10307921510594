#companyIndex {
  .dataTables_filter {
    label {
      display: none; } }
  .companySearch {
    input {
      padding-left: 17px; }
    .fa-search {
      position: absolute;
      top: 5px;
      left: 15px; }
    .fa-times {
      &:hover {
        cursor: pointer; }
      display: none;
      position: absolute;
      top: 5px;
      right: 15px; } } }

#companySearch {
  width: 100%; }

#addNewCompanyBtn, #identifyLevyBtn {
  margin-bottom: 15px; }

#checkCompanyContainer {
  padding-bottom: 15px; }

.center-btn {
  display: block;
  margin: auto; }

#noCompanyNameError, #noPostcodeError {
  padding-left: 15px; }

#showLevyEligibility {
  font-size: 20px;
  border: 0; }

[id^='levyRadioContainer_'] {
  flex: 0;
  flex-direction: row;
  justify-content: space-around;
  display: inline-flex;
  width: 100%; }

#levySelectionContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  margin-bottom: 15px; }

#createCompanyRecord {
  margin-right: 15px; }
