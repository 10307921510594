#viewCandidateCommitmentStatment {
  .col-xs-5 {
    padding-left: 0;
    padding-right: 0; } }

#commitmentStatementCont {
  font-family: 'Roboto', sans-serif;
  margin-right: -15px;
  margin-left: -15px; }

#commitmentStatementCont p {
  font-size: 18px; }
#commitmentStatementCont li {
  font-size: 18px; }
#commitmentStatementCont hr {
  border-bottom: 5px dotted #0075A8;
  margin-top: 30px;
  margin-bottom: 30px; }


#commitmentStatementCont .normal {
  font-weight: 400; }
#commitmentStatementCont .semi-bold {
  font-weight: 500; }
#commitmentStatementCont .bold {
  font-weight: 700; }
#commitmentStatementCont .heavy-bold {
  font-weight: 900; }


#commitmentStatementCont .font-size-25 {
  font-size: 25px; }


#commitmentStatementCont header {
  background: #0075A8;
  color: #FFF;
  padding: 30px 0; }

#commitmentStatementCont header h1 {
  font-size: 80px;
  letter-spacing: 3px;
  font-weight: 300;
  line-height: 1.1; }

#commitmentStatementCont .small-header {
  background: #0075A8;
  color: #FFF;
  padding: 30px 0; }

#commitmentStatementCont .sections {
  background: #00A1DF;
  color: #FFF;
  padding: 20px 0; }

#commitmentStatementCont .sections p {
  font-size: 20px; }

#commitmentStatementCont .logo {
  position: absolute;
  right: -24px;
  top: -80px;
  width: 250px; }


#commitmentStatementCont .dark-blue {
  color: #0075A8; }

#commitmentStatementCont .light-blue {
  color: #00A1DF; }

#commitmentStatementCont .yellow {
  color: #FFBF3B; }

#commitmentStatementCont .light-blue {
  color: #00A1DF; }

#commitmentStatementCont .white {
  color: #fff; }


#commitmentStatementCont .margin-top-0 {
  margin-top: 0; }
#commitmentStatementCont .margin-top-10 {
  margin-top: 10px; }
#commitmentStatementCont .margin-top-20 {
  margin-top: 20px; }
#commitmentStatementCont .margin-top-30 {
  margin-top: 30px; }
#commitmentStatementCont .margin-top-40 {
  margin-top: 40px; }
#commitmentStatementCont .margin-top-50 {
  margin-top: 60px; }
#commitmentStatementCont .margin-top-60 {
  margin-top: 60px; }
#commitmentStatementCont .margin-top-80 {
  margin-top: 80px; }
#commitmentStatementCont .margin-top-150 {
  margin-top: 150px; }

#commitmentStatementCont .margin-bottom-60 {
  margin-bottom: 60px; }

/** Margin Left **/
#commitmentStatementCont .margin-left-150 {
  margin-left: 150px; }

@media(min-width: 990px) {
  #commitmentStatementCont .logo-mobile {
    display: none; } }


@media(max-width: 992px) {
  #commitmentStatementCont .logo {
    display: none; }

  #commitmentStatementCont .logo {
    position: relative;
    right: 0px;
    top: 0px;
    width: 0px; }

  #commitmentStatementCont .margin-left-mobile {
    margin-left: 10px; } }



@media(max-width: 767px) {
  #commitmentStatementCont .margin-left-150 {
    margin-left: 10px; }

  #commitmentStatementCont header h1 {
    font-size: 35px; }

  #commitmentStatementCont .hand {
    width: 100px;
    display: -webkit-box;
    margin: 0 auto; }
  #commitmentStatementCont .container-mobile {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; } }
