@import "variables";

html {
  height: 100%; }

.login-background {
  display: block;
  height: 100%;
  width: 50%;
  position: fixed;
  transform: translate(-50%, -20%);
  right: 50%;
  left: 50%;
  top: 50%; }

.cp-body {
  background-color: $cpGrey; }

.body-no-sidebar {
  background-color: rgba(178, 126, 182, 0.7);
  background: radial-gradient(rgba(161,185,223,0.2), rgba(161,185,223,0.6)) no-repeat center center fixed; }

//.blue-arrow
//  width: 200px
//  height: 200px
//  position: absolute
//  top: 100px
//  left: 100%
//
//.cross-arrow
//  width: 200px
//  height: 200px
//  position: absolute
//  top: -196px
//  left: 35%
//
//.red-arrow
//  width: 200px
//  height: 200px
//  position: absolute
//  top: 120px
//  left: -30%
//  -moz-transform: scaleX(-1)
//  -o-transform: scaleX(-1)
//  -webkit-transform: scaleX(-1)
//  transform: scaleX(-1)
//  filter: FlipH
//  -ms-filter: "FlipH"
//
//
//.login-img
//  max-width: 100%
//  max-height: 100%


//.login-bar
//  background-color: white
//  height: 80vh
//  padding: 15px
//
//.login-page-content-left
//  max-height: 80vh
//  overflow: scroll
//  background-color: white
//
//.login-page-content-right
//  @extend .login-page-content-left
//
//.login-page-content
//  height: 80vh
//  margin-right: 15px
//
//.login-interserve-news-item
//  border: solid 1px #030303
//  max-height: 200px
//  overflow: hidden
//  margin: 10px
//
//.login-interserve-news-item-img
//  padding: 10px
//  max-height: 180px
//
//
//#loginForm
//  padding-top: 20px
//
//#loginFormTitle
//  font-size: 2.5em
//
//.login-form-label
//  margin-top: 20px
//
//#loginFormSubmit
//  margin-top: 20px
