@import "variables";

//List Styles

.list-indented {
  margin-left: 10px; }


//Select2 Custom Styles

//.select2-container .select2-selection--single
//height: 36px

//Progress Bar Styles

.progress,
.progress > .progress-bar,
.progress .progress-bar,
.progress > .progress-bar .progress-bar {
  border-radius: 1px; }

.progress-bar-green,
.progress-bar-success {
  background-color: #85be44; }

.progress-bar-yellow,
.progress-bar-warning {
  background-color: #f39c12; }

.progress-bar-red,
.progress-bar-danger {
  background-color: #dd4b39; }

//DataTable Styles

.table-bordered {
  border: 1px solid #f4f4f4; }

.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid #f4f4f4; }

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 2px; }

//Widget Styles

.whole-widget {
  background-color: #fff; }


//Select Item Styles

select {
  margin-bottom: 5px; }

.bootstrap-datetimepicker-widget {
  z-index: 9999; }
