@import "variables";
@import "components";

#postcodeHolder {
  padding-bottom: 20px; }

#postcodeDidYouMean {
  background-color: #fff;
  border: 1px;
  border-color: $blue40;
  border-style: solid; }

#postcodeDidYouMean h4 {
  margin-top: 10px; }

.bootstrap-date-timepicker {
  z-index: 9999999999;
  width: 400px;
  background-color: #000; }

#customerActionLog {
  overflow: visible; }
#editor {
  overflow: visible; }

#addNewCustomerForm {
  @extend #postcodeHolder;
  background-color: #fff; }

#addNewCustomerBtn {
  display: block;
  margin: auto; }

#actionLogSecondStep {
  position: relative; }

#actionLogFirstStep {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.9);
  z-index: 2; }

textarea#newCompanyAdditionalNote {
  width: 100%; }

textarea#editCompanyAdditionalNote {
  width: 100%; }

textarea#newContactAdditionalNote {
  width: 100%; }

.customer-engaged {
  background-color: $paleGreen;
  text-align: right;
  padding-right: 10%;
  font-size: 10px; }

.action-log {
  height: 30px!important; }

.customer-candidate {
  padding-top: 5px;
  border-bottom: #000 solid 1px; }

.customer-candidate-span {
  margin-bottom: 8px;
  margin-top: 5px;
  display: inline-block; }

#newContactTitle label {
  margin: 0; }
