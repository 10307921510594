@import "variables";

#taskList {
  width: 260px;
  padding: 10px 1px;
  margin-bottom: 26px;
  background-color: #fff;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px; }

#taskList li {
  list-style: none;
  text-align: left;
  padding: 5px 10px;
  margin: 3px 1px;
  background-color: $paleGreen; }


#taskList li {
  list-style: none;
  text-align: left;
  padding: 5px 10px;
  margin: 3px 1px;
  background-color: $paleGreen; }


.task-notes-icon {
  margin-right: 5px; }

.navbar-left-quick {
  display: none;
  background-color: $Grey;
  //height: 300px
  //width: 50px
  position: fixed;
  top: 95px;
  left: 0;
  z-index: 1; }

.navbar-left-quick-icon {
  padding: 3px;
  display: block;
  margin: 2px auto;
  color: #fff;
  text-align: center; }

//Navigation Styles

#sidebar-menu-wrapper {
  width: 260px;
  overflow: hidden; }

#sidebar-menu {
  position: relative;
  top: 0;
  left: 0;
  background: #263238;
  width: 260px;
  text-align: left;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px; }


//TODO Uncomment out this line if the side nav is going to be fixed
#leftSideMenu {
  position: fixed; }



//Session Container
#sessionContainer {
  position: absolute;
  top: 0;
  left: 260px;
  height: 500px; }

#sessionContainerFoot {
  position: relative;
  top: 90%;
  background-color: #fff;
  height: 10%;
  border-top: 1px solid #e5e5e5; }

#sessionContainerHead {
  position: relative;
  top: 0;
  background-color: #fff;
  height: 10%;
  border-bottom: solid 1px #e5e5e5; }





#sidebar-menu:before {
  background-size: cover;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  //background: #263238 url(../img/sidebar-menu-background-2.png) no-repeat bottom
  opacity: .1; }

.sidebar-inner .profile-info {
  text-align: center;
  padding-top: 20px;
  background: #fff;
  padding-bottom: 20px;
  margin-bottom: 20px; }

.sidebar-inner .profile-info-option {
  text-align: center; }
//padding-top: 10px
//padding-bottom: 20px

.tooltip-inner {
  background-color: $Blue; }
