@import "variables";

#staffProfileContactTitle {
  margin-left: 10%;
  margin-right: 10%;
  width: 80%; }

#staffProfileContactBody {
  @extend #staffProfileContactTitle;
  height: 100px;
  margin-top: 10px;
  margin-bottom: 10px; }

#staffProfileContactSend {
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10px; }

.staff-profile-img {
  max-height: 210px;
  max-width: 100%; }
