@import "variables";

.task-container {
  border-bottom: 1px solid $border; }

.task-container-campaign {
  border-bottom: 3px solid $Blue; }

.task-container-candidate {
  border-bottom: 3px solid $brightPurple; }

.task-container-customer {
  border-bottom: 3px solid $brightGreen; }

.dashboard-notification-container {
  border-bottom: 1px solid $border; }

.dropdown-menu-widget {
  position: absolute;
  top: 55px;
  right: 0px; }

.target-widget {
  font-size: 18px!important; }
