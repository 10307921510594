@import "variables";

// .modal
//   position: fixed
//   width: 50%
//   margin-right: 50%
//   margin-left: 50%
//   transform: translate(-50%, 10%)
//   border-radius: 5px

.modal-header {
  background-color: $LightGrey;
  padding: 15px 30px 15px; }

.md-close {
  position: absolute;
  background: 0;
  border: none;
  font-size: 3em;
  right: 5px;
  top: 5px; }

#modalBackground, .modalBackground {
  flex: 0;
  background-color: rgba(0,0,0,0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 11;
  display: none; }

.md-content > div {
  padding: 15px 15px 30px;
  margin: 0;
  font-weight: 300;
  font-size: 14px; }

.md-content {
  color: #5b5b5b;
  background: #fff;
  position: relative;
  margin: 0 auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; }

.md-content .md-close-btn {
  position: absolute;
  top: 10px;
  right: 5px;
  z-index: 999999; }

.md-content .md-close-btn a {
  color: #909090;
  cursor: pointer; }

.md-content .md-close-btn a:hover {
  color: #212121; }

.md-content .md-close {
  font-size: 13px; }

.md-content h3 {
  margin: 0;
  padding: 20px;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  opacity: 0.8;
  background: #fff;
  border-radius: 0;
  color: #4C5264; }

.md-content > div p {
  margin: 0;
  padding: 10px 0; }


.md-content > div ul {
  margin: 0;
 }  //padding: 0 0 30px 20px

.md-content > div ul li {
  padding: 5px 0; }

.modal-backdrop {
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  visibility: hidden !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 9999995 !important;
  opacity: 1 !important;
  background: rgba(1,1,1,0.2) !important;
  -webkit-transition: all 0.3s !important;
  -moz-transition: all 0.3s !important;
  transition: all 0.3s !important; }

@media(min-width: 768px) {
  #findSettingModal {
    .modal-dialog {
      width: 768px; } }
  #identifyCompanyModal {
    .modal-dialog {
      width: 768px; } }
  #identifyCandidateModal {
    .modal-dialog {
      width: 768px; } }
  #findCandidateModal {
    .modal-dialog {
      width: 768px; } } }
