@import "variables";

//Widget Styles

.widget .widget-header {
  height: 43px;
  display: block; }

.widget .widget-header .col-xs-8 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }


.widget .widget-header h2 {
  padding: 4px 13px 4px 0px;
  margin: 0px;
  font-weight: 400;
  font-size: 23px;
  display: block;
  color: #000; }


.ILE-widget {
  padding: 15px;
  border-radius: 5px;
  //box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 26px; }


.widgetHeaderTwo {
 }  //margin-left: 20px!important

.panel-master {
  background-color: #fff; }

.activity-widget {
  margin-bottom: 0;
  border-bottom: 3px solid #b9d478; }

#customerActivityLogCont div.activity-widget:nth-child(2) {
  border-radius: 5px 5px 0 0; }

#customerActivityLogCont div.activity-widget:last-child {
  border-radius: 0 0 5px 5px; }

.activity-widget {
  border-radius: 0; }

.activity-widget:hover {
  background-color: #f8f8f7; }

.ILE-widget--show .form-row {
  margin-right: -15px!important; }

.widget.collapsable .widget-content {
 display: none; }

.widget.collapsable .widget-content.expanded {
 display: block !important; }
